import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  NOT_AVAILABLE_VALUE,
  RESULTS_SELECTION_LIMIT_AMOUNT,
} from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { select, Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  Pagination,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  AddFilterParam,
  ColumnsSelected,
  HideColumn,
  Paginate,
  RemoveAllFilters,
  ResetImportDetails,
  Sort,
  ToggleFilter,
} from './actions/import-details-data-table.actions';
import { ImportsDataTableStateQueries } from './state/import-details-data-table.queries';
import { Errors } from '../../model/import-details.model';
import { STRINGS } from './model/import-details-data-table.strings';
import { ImportsDataTableState } from './state/import-details-data-table.state';
import { COLUMNS_ORDER } from './model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

@Component({
  selector: 'app-import-details-data-table',
  templateUrl: './import-details-data-table.component.html',
  styleUrls: ['./import-details-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDetailsDataTableComponent
  extends DataTableV2Component<Errors[]>
  implements Pagination, OnDestroy
{
  saving = select(AppState.isSaving);
  exportsEnabled = select(WebshopState.exportsEnabled);
  loading = select(ImportsDataTableStateQueries.loading);
  currentPage = select(ImportsDataTableStateQueries.currentPage);
  totalElements = select(ImportsDataTableStateQueries.totalElements);
  pageSizeOptions = select(ImportsDataTableStateQueries.pageSizeOptions);
  pageSize = select(ImportsDataTableStateQueries.pageSize);
  selectableColumns = select(ImportsDataTableStateQueries.selectableColumns);
  lastDisplayedColumn = select(
    ImportsDataTableStateQueries.lastDisplayedColumn
  );
  displayedColumns = select(ImportsDataTableStateQueries.displayedColumns);
  datasource = select(ImportsDataTableStateQueries.errorLines);
  filterOpened = select(ImportsDataTableStateQueries.filterOpened);
  activeFilters = select(ImportsDataTableStateQueries.activeFilters);
  filters = select(ImportsDataTableStateQueries.filters);
  searchBy = select(ImportsDataTableStateQueries.search);
  haveProducts = select(ImportsDataTableStateQueries.haveProducts);

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.errors;

  readonly TABLE_TOOLTIPS = STRINGS.tooltips;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly TABLE_METADATA = STRINGS.metadata;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RESULTS_SELECTION_LIMIT = RESULTS_SELECTION_LIMIT_AMOUNT;

  readonly RELATED_STATE = ImportsDataTableState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onSort(sort: Sorted): void {
    this.store.dispatch(new Sort(sort));
  }

  onColumnHidden(columnKey: string): void {
    this.store.dispatch(new HideColumn(columnKey));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.store.dispatch([new ResetImportDetails()]);
  }
}
