<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <button
      aria-label="Settings souces refresh button"
      appButton
      appButtonIcon
      size="sm"
      variant="link"
      class="refresh-icon"
      (click)="refresh()"
      [appTooltip]="TOOLTIPS.refresh">
      <mat-icon svgIcon="rotating"></mat-icon>
    </button>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.name.key">
      <th
        data-intercom-target="Imports data table name column"
        aria-label="Imports data table name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.name.key | filterColumn: RELATED_STATE | async
        "
        class="restricted-column">
        {{ TABLE_COLUMNS.name.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.fileName !== null; else notAvailable">
          <span>{{ element.fileName }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Source -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.source.key">
      <th
        data-intercom-target="Imports data table source column"
        aria-label="Imports data table source column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.source.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.source.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.entity !== null; else notAvailable">
          <span>{{ element.entityName }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Operation -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.operation.key">
      <th
        data-intercom-target="Imports data table operation column"
        aria-label="Imports data table operation column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.operation.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.operation.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.operation !== null; else notAvailable">
          <span>{{ element.operation }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Identifier -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.identifier.key">
      <th
        data-intercom-target="Imports data table identifier column"
        aria-label="Imports data table identifier column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.identifier.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.identifier.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.entity !== null; else notAvailable">
          <span>{{ element.entity.identifier | sentenceCase }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Created -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.createdAt.key">
      <th
        data-intercom-target="Imports data table created column"
        aria-label="Imports data table created column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.createdAt.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.createdAt.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.createdAt !== null; else notAvailable">
          <span>{{ element.createdAt | date }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Records -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.records.key">
      <th
        data-intercom-target="Imports data table records column"
        aria-label="Imports data table records column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.records.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.records.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container *ngIf="element.totalRecords !== null; else notAvailable">
          <span>{{ element.totalRecords }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.status.key">
      <th
        data-intercom-target="Imports data table status column"
        aria-label="Imports data table status column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.status.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.status.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.state !== 'IMPORTING' && element.state !== 'IN_QUEUE'">
          <ng-container *ngFor="let tag of element.extras.tags.textTags">
            <app-tag
              [color]="tag.color"
              [appTooltip]="tag.tooltip || null"
              rounded>
              {{ tag.text }}
            </app-tag>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="element.state === 'IMPORTING'">
          <div class="importing-spinner">
            <app-progress-spinner diameter="20" mode="indeterminate">
            </app-progress-spinner>
            <p>
              {{ TABLE_STATES.loading }}
            </p>
          </div>
        </ng-container>

        <ng-container *ngIf="element.state === 'IN_QUEUE'">
          <div class="importing-spinner">
            <app-progress-spinner diameter="20" mode="indeterminate">
            </app-progress-spinner>
            <p>
              {{ TABLE_STATES.inQueue }}
            </p>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.actions.key" stickyEnd>
      <th
        data-intercom-target="Imports data table actions column"
        aria-label="Imports data table actions column"
        cdk-header-cell
        *cdkHeaderCellDef
        class="actions-column fit-to-value"></th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="actions-container align-right fit-to-value"
        (click)="$event.stopPropagation()">
        <div class="actions-options">
          <button
            *ngIf="
              element.state === 'INVALID_OPERATION_TYPE' ||
              element.state === 'FAILED_HEADERS' ||
              element.state === 'FAILED_WITH_VALIDATION_ERRORS' ||
              element.state === 'COMPLETED_WITH_ERRORS'
            "
            data-intercom-target="Imports data table view errors button"
            aria-label="Imports data table view errors button"
            appButton
            variant="danger"
            class="table-button"
            (click)="onImportSelected(element)"
            appTooltip="View errors">
            <mat-icon svgIcon="warning-rounded"></mat-icon>
          </button>

          <button
            *ngIf="element.state === 'FAILED_UNEXPECTEDLY'"
            data-intercom-target="Imports data table retry button"
            aria-label="Imports data table retry button"
            appButton
            variant="primary"
            class="table-button"
            appTooltip="Retry Import"
            (click)="retry(element.uuid)">
            <mat-icon svgIcon="rotating-xs"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>

<ng-template #absolute let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="no-percentage">%</span>
</ng-template>

<ng-template #relative let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="gray">%</span>
</ng-template>
